/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  DataOKResponseResponse,
  BadRequestResponse,
  InternalServerErrorResponse,
  AdminUpdateUserStatusBodies,
  PatchClinicAccountFinishedFirstLoginFlagBodies,
  GetClinicAccountsByEmailResponseResponse,
  GetClinicAccountsByClinicIdResponseResponse,
  SuccessResponseResponse,
  PostClinicAccountsBodies,
  PatchClinicAccountBodies,
  PatchClinicAccountPasswordBodies,
  PatchClinicAccountEmailBodies,
  PatchClinicAccountGoogleTokenBodies,
  PatchClinicAccountFcmRegistrationTokenBodies,
  PatchClinicAccountDefaultBlurFlagBodies,
  GetClinicResponseResponse,
  SuccessResponse,
  PatchClinicsBodies,
  PostReservationSettingsBodies,
  GetReservationSettingResponseResponse,
  PatchReservationSettingsBodies,
  PatchClinicConsultationHoursSettingsEachDayBodies,
  PatchClinicConsultationHoursSettingsDisableTimeBodies,
  PatchClinicConsultationHoursSettingsPeriodBodies,
  PatchClinicOpeningSettingBodies,
  PostReservationSlotsBodies,
  GetClinicsIdPatientsResponseResponse,
  PostCsvDoctorConsultation200,
  PostCsvDoctorConsultationBodies,
  PostPaymentBodies,
  DeletePaymentBodies,
  SuccessPostResponseIdResponse,
  PostPaymentItemsBodies,
  GetPaymentItemsByClinicId200,
  PostConsultationsRoomResponseResponse,
  PostConsultationsRoomBodies
} from '.././model'
import { useCustomClient } from '../../apiClientConfig/customClient';
import type { ErrorType, BodyType } from '../../apiClientConfig/customClient';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * # 仕様
- ユーザを有効化または無効化する
 * @summary ユーザの有効化・無効化API
 */
export const useAdminUpdateUserStatusHook = () => {
        const adminUpdateUserStatus = useCustomClient<DataOKResponseResponse>();

        return (
    adminUpdateUserStatusBodies: BodyType<AdminUpdateUserStatusBodies>,
 ) => {
        return adminUpdateUserStatus(
          {url: `/v1/clinics/adminUpdateUserStatus`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: adminUpdateUserStatusBodies
    },
          );
        }
      }
    


export const useAdminUpdateUserStatusMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAdminUpdateUserStatusHook>>>, TError,{data: BodyType<AdminUpdateUserStatusBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAdminUpdateUserStatusHook>>>, TError,{data: BodyType<AdminUpdateUserStatusBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const adminUpdateUserStatus =  useAdminUpdateUserStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAdminUpdateUserStatusHook>>>, {data: BodyType<AdminUpdateUserStatusBodies>}> = (props) => {
          const {data} = props ?? {};

          return  adminUpdateUserStatus(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AdminUpdateUserStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAdminUpdateUserStatusHook>>>>
    export type AdminUpdateUserStatusMutationBody = BodyType<AdminUpdateUserStatusBodies>
    export type AdminUpdateUserStatusMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary ユーザの有効化・無効化API
 */
export const useAdminUpdateUserStatus = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAdminUpdateUserStatusHook>>>, TError,{data: BodyType<AdminUpdateUserStatusBodies>}, TContext>, }
) => {
    
      const mutationOptions = useAdminUpdateUserStatusMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックユーザの初回ログインフラグを1にする
 * @summary クリニックユーザの初回ログイン済みフラグ
 */
export const usePatchClinicAccountFinishedFirstLoginFlagHook = () => {
        const patchClinicAccountFinishedFirstLoginFlag = useCustomClient<DataOKResponseResponse>();

        return (
    patchClinicAccountFinishedFirstLoginFlagBodies: BodyType<PatchClinicAccountFinishedFirstLoginFlagBodies>,
 ) => {
        return patchClinicAccountFinishedFirstLoginFlag(
          {url: `/v1/clinics/patchClinicAccountFinishedFirstLoginFlag`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountFinishedFirstLoginFlagBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountFinishedFirstLoginFlagMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFinishedFirstLoginFlagHook>>>, TError,{data: BodyType<PatchClinicAccountFinishedFirstLoginFlagBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFinishedFirstLoginFlagHook>>>, TError,{data: BodyType<PatchClinicAccountFinishedFirstLoginFlagBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccountFinishedFirstLoginFlag =  usePatchClinicAccountFinishedFirstLoginFlagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFinishedFirstLoginFlagHook>>>, {data: BodyType<PatchClinicAccountFinishedFirstLoginFlagBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchClinicAccountFinishedFirstLoginFlag(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountFinishedFirstLoginFlagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFinishedFirstLoginFlagHook>>>>
    export type PatchClinicAccountFinishedFirstLoginFlagMutationBody = BodyType<PatchClinicAccountFinishedFirstLoginFlagBodies>
    export type PatchClinicAccountFinishedFirstLoginFlagMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックユーザの初回ログイン済みフラグ
 */
export const usePatchClinicAccountFinishedFirstLoginFlag = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFinishedFirstLoginFlagHook>>>, TError,{data: BodyType<PatchClinicAccountFinishedFirstLoginFlagBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountFinishedFirstLoginFlagMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- リクエストパラメーターのメールアドレスに紐づくクリニックアカウントを返す

 * @summary クリニックアカウント取得API(email)
 */
export const useGetClinicAccountsByEmailHook = () => {
        const getClinicAccountsByEmail = useCustomClient<GetClinicAccountsByEmailResponseResponse>();

        return (
    email: string,
 signal?: AbortSignal
) => {
        return getClinicAccountsByEmail(
          {url: `/v1/clinicAccounts/email/${email}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicAccountsByEmailQueryKey = (email: string,) => [`/v1/clinicAccounts/email/${email}`] as const;
  

    
export const useGetClinicAccountsByEmailQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicAccountsByEmailQueryKey(email);

  const getClinicAccountsByEmail =  useGetClinicAccountsByEmailHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>> = ({ signal }) => getClinicAccountsByEmail(email, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(email), ...queryOptions}}

export type GetClinicAccountsByEmailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>>
export type GetClinicAccountsByEmailQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニックアカウント取得API(email)
 */
export const useGetClinicAccountsByEmail = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByEmailHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicAccountsByEmailQueryOptions(email,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- リクエストパラメーターのメールアドレスに紐づくクリニックアカウントを返す

 * @summary クリニックアカウント取得API(クリニックID)
 */
export const useGetClinicAccountsByClinicIdHook = () => {
        const getClinicAccountsByClinicId = useCustomClient<GetClinicAccountsByClinicIdResponseResponse>();

        return (
    clinicId: string,
 signal?: AbortSignal
) => {
        return getClinicAccountsByClinicId(
          {url: `/v1/clinicAccounts/clinic/${clinicId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicAccountsByClinicIdQueryKey = (clinicId: string,) => [`/v1/clinicAccounts/clinic/${clinicId}`] as const;
  

    
export const useGetClinicAccountsByClinicIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicAccountsByClinicIdQueryKey(clinicId);

  const getClinicAccountsByClinicId =  useGetClinicAccountsByClinicIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>> = ({ signal }) => getClinicAccountsByClinicId(clinicId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions}}

export type GetClinicAccountsByClinicIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>>
export type GetClinicAccountsByClinicIdQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニックアカウント取得API(クリニックID)
 */
export const useGetClinicAccountsByClinicId = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsByClinicIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicAccountsByClinicIdQueryOptions(clinicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- リクエストパラメーターのメールアドレスと権限でクリニックアカウントを作成する

 * @summary クリニックアカウント作成API
 */
export const usePostClinicAccountsHook = () => {
        const postClinicAccounts = useCustomClient<SuccessResponseResponse>();

        return (
    postClinicAccountsBodies: BodyType<PostClinicAccountsBodies>,
 ) => {
        return postClinicAccounts(
          {url: `/v1/clinicAccounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postClinicAccountsBodies
    },
          );
        }
      }
    


export const usePostClinicAccountsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostClinicAccountsHook>>>, TError,{data: BodyType<PostClinicAccountsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostClinicAccountsHook>>>, TError,{data: BodyType<PostClinicAccountsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postClinicAccounts =  usePostClinicAccountsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostClinicAccountsHook>>>, {data: BodyType<PostClinicAccountsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postClinicAccounts(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostClinicAccountsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostClinicAccountsHook>>>>
    export type PostClinicAccountsMutationBody = BodyType<PostClinicAccountsBodies>
    export type PostClinicAccountsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウント作成API
 */
export const usePostClinicAccounts = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostClinicAccountsHook>>>, TError,{data: BodyType<PostClinicAccountsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostClinicAccountsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックIDに紐づくアカウント情報を更新する。
- 自分と同等・それ以下の権限へしか変更を行えないよう制御する。
- 代表医師が別のロールに変更する場合、同じ医療機関内に他の代表医師がいるかチェックする。
  - 変更対象のユーザーが、唯一の代表医師であればエラーを発生させる。

 * @summary クリニックアカウント更新API
 */
export const usePatchClinicAccountHook = () => {
        const patchClinicAccount = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchClinicAccountBodies: BodyType<PatchClinicAccountBodies>,
 ) => {
        return patchClinicAccount(
          {url: `/v1/clinicAccounts/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccount =  usePatchClinicAccountHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountHook>>>, {id: string;data: BodyType<PatchClinicAccountBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinicAccount(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountHook>>>>
    export type PatchClinicAccountMutationBody = BodyType<PatchClinicAccountBodies>
    export type PatchClinicAccountMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウント更新API
 */
export const usePatchClinicAccount = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックIDに紐づくアカウント情報のパスワードを更新する。
- パスワードは8文字以上とする

 * @summary クリニックアカウントパスワード更新API
 */
export const usePatchClinicAccountPasswordHook = () => {
        const patchClinicAccountPassword = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchClinicAccountPasswordBodies: BodyType<PatchClinicAccountPasswordBodies>,
 ) => {
        return patchClinicAccountPassword(
          {url: `/v1/clinicAccounts/${id}/password`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountPasswordBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountPasswordMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountPasswordHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountPasswordBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountPasswordHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountPasswordBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccountPassword =  usePatchClinicAccountPasswordHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountPasswordHook>>>, {id: string;data: BodyType<PatchClinicAccountPasswordBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinicAccountPassword(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountPasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountPasswordHook>>>>
    export type PatchClinicAccountPasswordMutationBody = BodyType<PatchClinicAccountPasswordBodies>
    export type PatchClinicAccountPasswordMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウントパスワード更新API
 */
export const usePatchClinicAccountPassword = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountPasswordHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountPasswordBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountPasswordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックIDに紐づくアカウント情報のメールアドレスを更新する。
- 同時に認証で使われているメールアドレスも更新する。

 * @summary クリニックアカウントEmail更新API
 */
export const usePatchClinicAccountEmailHook = () => {
        const patchClinicAccountEmail = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchClinicAccountEmailBodies: BodyType<PatchClinicAccountEmailBodies>,
 ) => {
        return patchClinicAccountEmail(
          {url: `/v1/clinicAccounts/${id}/email`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountEmailBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountEmailMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountEmailHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountEmailBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountEmailHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountEmailBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccountEmail =  usePatchClinicAccountEmailHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountEmailHook>>>, {id: string;data: BodyType<PatchClinicAccountEmailBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinicAccountEmail(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountEmailMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountEmailHook>>>>
    export type PatchClinicAccountEmailMutationBody = BodyType<PatchClinicAccountEmailBodies>
    export type PatchClinicAccountEmailMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウントEmail更新API
 */
export const usePatchClinicAccountEmail = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountEmailHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountEmailBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountEmailMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックIDに紐づくアカウント情報のGoogleトークンを更新する。

 * @summary クリニックアカウントGoogleトークン更新API
 */
export const usePatchClinicAccountGoogleTokenHook = () => {
        const patchClinicAccountGoogleToken = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchClinicAccountGoogleTokenBodies: BodyType<PatchClinicAccountGoogleTokenBodies>,
 ) => {
        return patchClinicAccountGoogleToken(
          {url: `/v1/clinicAccounts/${id}/googleToken`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountGoogleTokenBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountGoogleTokenMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountGoogleTokenHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountGoogleTokenBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountGoogleTokenHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountGoogleTokenBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccountGoogleToken =  usePatchClinicAccountGoogleTokenHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountGoogleTokenHook>>>, {id: string;data: BodyType<PatchClinicAccountGoogleTokenBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinicAccountGoogleToken(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountGoogleTokenMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountGoogleTokenHook>>>>
    export type PatchClinicAccountGoogleTokenMutationBody = BodyType<PatchClinicAccountGoogleTokenBodies>
    export type PatchClinicAccountGoogleTokenMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウントGoogleトークン更新API
 */
export const usePatchClinicAccountGoogleToken = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountGoogleTokenHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountGoogleTokenBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountGoogleTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックIDに紐づくアカウント情報のFCM登録トークンを更新する。

 * @summary クリニックアカウントFCM登録トークン更新API
 */
export const usePatchClinicAccountFcmRegistrationTokenHook = () => {
        const patchClinicAccountFcmRegistrationToken = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchClinicAccountFcmRegistrationTokenBodies: BodyType<PatchClinicAccountFcmRegistrationTokenBodies>,
 ) => {
        return patchClinicAccountFcmRegistrationToken(
          {url: `/v1/clinicAccounts/${id}/fcmRegistrationToken`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountFcmRegistrationTokenBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountFcmRegistrationTokenMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFcmRegistrationTokenHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountFcmRegistrationTokenBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFcmRegistrationTokenHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountFcmRegistrationTokenBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccountFcmRegistrationToken =  usePatchClinicAccountFcmRegistrationTokenHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFcmRegistrationTokenHook>>>, {id: string;data: BodyType<PatchClinicAccountFcmRegistrationTokenBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinicAccountFcmRegistrationToken(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountFcmRegistrationTokenMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFcmRegistrationTokenHook>>>>
    export type PatchClinicAccountFcmRegistrationTokenMutationBody = BodyType<PatchClinicAccountFcmRegistrationTokenBodies>
    export type PatchClinicAccountFcmRegistrationTokenMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウントFCM登録トークン更新API
 */
export const usePatchClinicAccountFcmRegistrationToken = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountFcmRegistrationTokenHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountFcmRegistrationTokenBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountFcmRegistrationTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニックIDに紐づくアカウント情報のデフォルトのぼかしフラグを更新する。

 * @summary クリニックアカウントデフォルトのぼかしフラグ更新API
 */
export const usePatchClinicAccountDefaultBlurFlagHook = () => {
        const patchClinicAccountDefaultBlurFlag = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchClinicAccountDefaultBlurFlagBodies: BodyType<PatchClinicAccountDefaultBlurFlagBodies>,
 ) => {
        return patchClinicAccountDefaultBlurFlag(
          {url: `/v1/clinicAccounts/${id}/defaultBlurFlag`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicAccountDefaultBlurFlagBodies
    },
          );
        }
      }
    


export const usePatchClinicAccountDefaultBlurFlagMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountDefaultBlurFlagHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountDefaultBlurFlagBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountDefaultBlurFlagHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountDefaultBlurFlagBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicAccountDefaultBlurFlag =  usePatchClinicAccountDefaultBlurFlagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountDefaultBlurFlagHook>>>, {id: string;data: BodyType<PatchClinicAccountDefaultBlurFlagBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinicAccountDefaultBlurFlag(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicAccountDefaultBlurFlagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountDefaultBlurFlagHook>>>>
    export type PatchClinicAccountDefaultBlurFlagMutationBody = BodyType<PatchClinicAccountDefaultBlurFlagBodies>
    export type PatchClinicAccountDefaultBlurFlagMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニックアカウントデフォルトのぼかしフラグ更新API
 */
export const usePatchClinicAccountDefaultBlurFlag = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicAccountDefaultBlurFlagHook>>>, TError,{id: string;data: BodyType<PatchClinicAccountDefaultBlurFlagBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicAccountDefaultBlurFlagMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- リクエストパラメーターのIDに紐づくクリニック情報を返す

 * @summary クリニックアカウント取得API
 */
export const useGetClinicHook = () => {
        const getClinic = useCustomClient<GetClinicResponseResponse>();

        return (
    id: string,
 signal?: AbortSignal
) => {
        return getClinic(
          {url: `/v1/clinics/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicQueryKey = (id: string,) => [`/v1/clinics/${id}`] as const;
  

    
export const useGetClinicQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicQueryKey(id);

  const getClinic =  useGetClinicHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>> = ({ signal }) => getClinic(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetClinicQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>>
export type GetClinicQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニックアカウント取得API
 */
export const useGetClinic = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- クリニック情報を更新する
- clinic画面に初回ログイン時にクリニック情報を入力する

 * @summary クリニック情報更新API
 */
export const usePatchClinicsHook = () => {
        const patchClinics = useCustomClient<SuccessResponse>();

        return (
    id: string,
    patchClinicsBodies: BodyType<PatchClinicsBodies>,
 ) => {
        return patchClinics(
          {url: `/v1/clinics/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicsBodies
    },
          );
        }
      }
    


export const usePatchClinicsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicsHook>>>, TError,{id: string;data: BodyType<PatchClinicsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicsHook>>>, TError,{id: string;data: BodyType<PatchClinicsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinics =  usePatchClinicsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicsHook>>>, {id: string;data: BodyType<PatchClinicsBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchClinics(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicsHook>>>>
    export type PatchClinicsMutationBody = BodyType<PatchClinicsBodies>
    export type PatchClinicsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニック情報更新API
 */
export const usePatchClinics = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicsHook>>>, TError,{id: string;data: BodyType<PatchClinicsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 曜日ごとの予約可能時間（eachDaySetting）, 予約不可時間（disableTimeSettings）, 予約期間（periodSetting）の設定を保存する
- 各設定は個別で保存されることを想定しているためoptionalに設定している。
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-29434&t=tAtZDeVodjTxKXHz-4

 * @summary 予約設定保存API
 */
export const usePostReservationSettingsHook = () => {
        const postReservationSettings = useCustomClient<SuccessResponse>();

        return (
    postReservationSettingsBodies: BodyType<PostReservationSettingsBodies>,
 ) => {
        return postReservationSettings(
          {url: `/v1/reservation/settings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postReservationSettingsBodies
    },
          );
        }
      }
    


export const usePostReservationSettingsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSettingsHook>>>, TError,{data: BodyType<PostReservationSettingsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSettingsHook>>>, TError,{data: BodyType<PostReservationSettingsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postReservationSettings =  usePostReservationSettingsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostReservationSettingsHook>>>, {data: BodyType<PostReservationSettingsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postReservationSettings(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostReservationSettingsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostReservationSettingsHook>>>>
    export type PostReservationSettingsMutationBody = BodyType<PostReservationSettingsBodies>
    export type PostReservationSettingsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 予約設定保存API
 */
export const usePostReservationSettings = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSettingsHook>>>, TError,{data: BodyType<PostReservationSettingsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostReservationSettingsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- requestParameterのIDに紐づくスケジュール設定曜日別予約可能時間を1件返す
- disableTimeSettingsは当日以降の設定を返す

 * @summary 予約設定取得API
 */
export const useGetReservationSettingHook = () => {
        const getReservationSetting = useCustomClient<GetReservationSettingResponseResponse>();

        return (
    id: string,
 signal?: AbortSignal
) => {
        return getReservationSetting(
          {url: `/v1/reservation/settings/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetReservationSettingQueryKey = (id: string,) => [`/v1/reservation/settings/${id}`] as const;
  

    
export const useGetReservationSettingQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReservationSettingQueryKey(id);

  const getReservationSetting =  useGetReservationSettingHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>> = ({ signal }) => getReservationSetting(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetReservationSettingQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>>
export type GetReservationSettingQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 予約設定取得API
 */
export const useGetReservationSetting = <TData = Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReservationSettingHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetReservationSettingQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 曜日ごとの予約可能時間（eachDaySetting）, 予約期間（periodSetting）の設定を更新する
- 各設定は個別で保存されることを想定しているためoptionalに設定している。
- 予約不可時間の更新時は予約枠をチェックし、すでに予約枠が入っている箇所に不可時間を設定しようとした場合はエラーを返す。
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-29434&t=tAtZDeVodjTxKXHz-4

 * @summary 予約設定更新API
 */
export const usePatchReservationSettingsHook = () => {
        const patchReservationSettings = useCustomClient<SuccessResponse>();

        return (
    id: string,
    patchReservationSettingsBodies: BodyType<PatchReservationSettingsBodies>,
 ) => {
        return patchReservationSettings(
          {url: `/v1/reservation/settings/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchReservationSettingsBodies
    },
          );
        }
      }
    


export const usePatchReservationSettingsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchReservationSettingsHook>>>, TError,{id: string;data: BodyType<PatchReservationSettingsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchReservationSettingsHook>>>, TError,{id: string;data: BodyType<PatchReservationSettingsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchReservationSettings =  usePatchReservationSettingsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchReservationSettingsHook>>>, {id: string;data: BodyType<PatchReservationSettingsBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchReservationSettings(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchReservationSettingsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchReservationSettingsHook>>>>
    export type PatchReservationSettingsMutationBody = BodyType<PatchReservationSettingsBodies>
    export type PatchReservationSettingsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 予約設定更新API
 */
export const usePatchReservationSettings = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchReservationSettingsHook>>>, TError,{id: string;data: BodyType<PatchReservationSettingsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchReservationSettingsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 曜日ごとの予約可能時間（eachDaySetting）の設定を保存する
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-29434&t=tAtZDeVodjTxKXHz-4

 * @summary 医療機関診療時間曜日別設定保存API
 */
export const usePatchClinicConsultationHoursSettingsEachDayHook = () => {
        const patchClinicConsultationHoursSettingsEachDay = useCustomClient<SuccessResponse>();

        return (
    clinicId: string,
    patchClinicConsultationHoursSettingsEachDayBodies: BodyType<PatchClinicConsultationHoursSettingsEachDayBodies>,
 ) => {
        return patchClinicConsultationHoursSettingsEachDay(
          {url: `/v1/consultationHoursSettings/${clinicId}/eachDay`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicConsultationHoursSettingsEachDayBodies
    },
          );
        }
      }
    


export const usePatchClinicConsultationHoursSettingsEachDayMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsEachDayHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsEachDayBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsEachDayHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsEachDayBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicConsultationHoursSettingsEachDay =  usePatchClinicConsultationHoursSettingsEachDayHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsEachDayHook>>>, {clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsEachDayBodies>}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  patchClinicConsultationHoursSettingsEachDay(clinicId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicConsultationHoursSettingsEachDayMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsEachDayHook>>>>
    export type PatchClinicConsultationHoursSettingsEachDayMutationBody = BodyType<PatchClinicConsultationHoursSettingsEachDayBodies>
    export type PatchClinicConsultationHoursSettingsEachDayMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 医療機関診療時間曜日別設定保存API
 */
export const usePatchClinicConsultationHoursSettingsEachDay = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsEachDayHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsEachDayBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicConsultationHoursSettingsEachDayMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 予約不可時間（disableTimeSettings）の設定を保存する

 * @summary 医療機関診療時間予約不可時間設定保存API
 */
export const usePatchClinicConsultationHoursSettingsDisableTimeHook = () => {
        const patchClinicConsultationHoursSettingsDisableTime = useCustomClient<SuccessResponse>();

        return (
    clinicId: string,
    patchClinicConsultationHoursSettingsDisableTimeBodies: BodyType<PatchClinicConsultationHoursSettingsDisableTimeBodies>,
 ) => {
        return patchClinicConsultationHoursSettingsDisableTime(
          {url: `/v1/consultationHoursSettings/${clinicId}/disableTime`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicConsultationHoursSettingsDisableTimeBodies
    },
          );
        }
      }
    


export const usePatchClinicConsultationHoursSettingsDisableTimeMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsDisableTimeHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsDisableTimeBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsDisableTimeHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsDisableTimeBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicConsultationHoursSettingsDisableTime =  usePatchClinicConsultationHoursSettingsDisableTimeHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsDisableTimeHook>>>, {clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsDisableTimeBodies>}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  patchClinicConsultationHoursSettingsDisableTime(clinicId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicConsultationHoursSettingsDisableTimeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsDisableTimeHook>>>>
    export type PatchClinicConsultationHoursSettingsDisableTimeMutationBody = BodyType<PatchClinicConsultationHoursSettingsDisableTimeBodies>
    export type PatchClinicConsultationHoursSettingsDisableTimeMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 医療機関診療時間予約不可時間設定保存API
 */
export const usePatchClinicConsultationHoursSettingsDisableTime = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsDisableTimeHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsDisableTimeBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicConsultationHoursSettingsDisableTimeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 予約期間（periodSetting）の設定を保存する

 * @summary 医療機関診療時間曜日別設定保存API
 */
export const usePatchClinicConsultationHoursSettingsPeriodHook = () => {
        const patchClinicConsultationHoursSettingsPeriod = useCustomClient<SuccessResponse>();

        return (
    clinicId: string,
    patchClinicConsultationHoursSettingsPeriodBodies: BodyType<PatchClinicConsultationHoursSettingsPeriodBodies>,
 ) => {
        return patchClinicConsultationHoursSettingsPeriod(
          {url: `/v1/consultationHoursSettings/${clinicId}/period`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicConsultationHoursSettingsPeriodBodies
    },
          );
        }
      }
    


export const usePatchClinicConsultationHoursSettingsPeriodMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsPeriodHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsPeriodBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsPeriodHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsPeriodBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicConsultationHoursSettingsPeriod =  usePatchClinicConsultationHoursSettingsPeriodHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsPeriodHook>>>, {clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsPeriodBodies>}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  patchClinicConsultationHoursSettingsPeriod(clinicId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicConsultationHoursSettingsPeriodMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsPeriodHook>>>>
    export type PatchClinicConsultationHoursSettingsPeriodMutationBody = BodyType<PatchClinicConsultationHoursSettingsPeriodBodies>
    export type PatchClinicConsultationHoursSettingsPeriodMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 医療機関診療時間曜日別設定保存API
 */
export const usePatchClinicConsultationHoursSettingsPeriod = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicConsultationHoursSettingsPeriodHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicConsultationHoursSettingsPeriodBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicConsultationHoursSettingsPeriodMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 曜日ごとの予約可能時間（eachDaySetting）, 予約期間（periodSetting）の設定を更新する
- 各設定は個別で保存されることを想定しているためoptionalに設定している。
- 予約不可時間の更新時は予約枠をチェックし、すでに予約枠が入っている箇所に不可時間を設定しようとした場合はエラーを返す。
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-29434&t=tAtZDeVodjTxKXHz-4

 * @summary 医療機関診療時間設定更新API
 */
export const usePatchClinicOpeningSettingHook = () => {
        const patchClinicOpeningSetting = useCustomClient<SuccessResponse>();

        return (
    clinicId: string,
    patchClinicOpeningSettingBodies: BodyType<PatchClinicOpeningSettingBodies>,
 ) => {
        return patchClinicOpeningSetting(
          {url: `/v1/consultationHoursSettings/${clinicId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchClinicOpeningSettingBodies
    },
          );
        }
      }
    


export const usePatchClinicOpeningSettingMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicOpeningSettingHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicOpeningSettingBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicOpeningSettingHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicOpeningSettingBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchClinicOpeningSetting =  usePatchClinicOpeningSettingHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchClinicOpeningSettingHook>>>, {clinicId: string;data: BodyType<PatchClinicOpeningSettingBodies>}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  patchClinicOpeningSetting(clinicId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchClinicOpeningSettingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchClinicOpeningSettingHook>>>>
    export type PatchClinicOpeningSettingMutationBody = BodyType<PatchClinicOpeningSettingBodies>
    export type PatchClinicOpeningSettingMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 医療機関診療時間設定更新API
 */
export const usePatchClinicOpeningSetting = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchClinicOpeningSettingHook>>>, TError,{clinicId: string;data: BodyType<PatchClinicOpeningSettingBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchClinicOpeningSettingMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 予約枠をリクエスト時から35日間分作成する。

 * @summary 予約可能枠作成API
 */
export const usePostReservationSlotsHook = () => {
        const postReservationSlots = useCustomClient<SuccessResponse>();

        return (
    postReservationSlotsBodies: BodyType<PostReservationSlotsBodies>,
 ) => {
        return postReservationSlots(
          {url: `/v1/reservation/slots`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postReservationSlotsBodies
    },
          );
        }
      }
    


export const usePostReservationSlotsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsHook>>>, TError,{data: BodyType<PostReservationSlotsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsHook>>>, TError,{data: BodyType<PostReservationSlotsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postReservationSlots =  usePostReservationSlotsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsHook>>>, {data: BodyType<PostReservationSlotsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postReservationSlots(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostReservationSlotsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsHook>>>>
    export type PostReservationSlotsMutationBody = BodyType<PostReservationSlotsBodies>
    export type PostReservationSlotsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 予約可能枠作成API
 */
export const usePostReservationSlots = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsHook>>>, TError,{data: BodyType<PostReservationSlotsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostReservationSlotsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 画面
- https://onl.bz/JCKrESV

# 仕様
- 対象医療機関に一度でも予約が入ったことがある患者のデータを取得する

 * @summary 患者リスト取得API
 */
export const useGetClinicsIdPatientsHook = () => {
        const getClinicsIdPatients = useCustomClient<GetClinicsIdPatientsResponseResponse>();

        return (
    id: string,
 signal?: AbortSignal
) => {
        return getClinicsIdPatients(
          {url: `/v1/clinics/${id}/patients`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicsIdPatientsQueryKey = (id: string,) => [`/v1/clinics/${id}/patients`] as const;
  

    
export const useGetClinicsIdPatientsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicsIdPatientsQueryKey(id);

  const getClinicsIdPatients =  useGetClinicsIdPatientsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>> = ({ signal }) => getClinicsIdPatients(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetClinicsIdPatientsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>>
export type GetClinicsIdPatientsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 患者リスト取得API
 */
export const useGetClinicsIdPatients = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicsIdPatientsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicsIdPatientsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 指定された医師IDや期間などの医師の稼働データをCSVデータとして作成する

 * @summary 医師の稼働CSV作成API
 */
export const usePostCsvDoctorConsultationHook = () => {
        const postCsvDoctorConsultation = useCustomClient<PostCsvDoctorConsultation200>();

        return (
    postCsvDoctorConsultationBodies: BodyType<PostCsvDoctorConsultationBodies>,
 ) => {
        return postCsvDoctorConsultation(
          {url: `/v1/clinics/csvDoctorConsultation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postCsvDoctorConsultationBodies
    },
          );
        }
      }
    


export const usePostCsvDoctorConsultationMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostCsvDoctorConsultationHook>>>, TError,{data: BodyType<PostCsvDoctorConsultationBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostCsvDoctorConsultationHook>>>, TError,{data: BodyType<PostCsvDoctorConsultationBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postCsvDoctorConsultation =  usePostCsvDoctorConsultationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostCsvDoctorConsultationHook>>>, {data: BodyType<PostCsvDoctorConsultationBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postCsvDoctorConsultation(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostCsvDoctorConsultationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostCsvDoctorConsultationHook>>>>
    export type PostCsvDoctorConsultationMutationBody = BodyType<PostCsvDoctorConsultationBodies>
    export type PostCsvDoctorConsultationMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 医師の稼働CSV作成API
 */
export const usePostCsvDoctorConsultation = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostCsvDoctorConsultationHook>>>, TError,{data: BodyType<PostCsvDoctorConsultationBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostCsvDoctorConsultationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 精算情報の作成
- 一度払い戻しがあった場合は、再度オーソリを作成し精算情報を更新する

# 画面URL
- https://onl.la/81CSvfV
 * @summary 精算情報作成API
 */
export const usePostPaymentHook = () => {
        const postPayment = useCustomClient<SuccessResponse>();

        return (
    postPaymentBodies: BodyType<PostPaymentBodies>,
 ) => {
        return postPayment(
          {url: `/v1/payment`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postPaymentBodies
    },
          );
        }
      }
    


export const usePostPaymentMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPaymentHook>>>, TError,{data: BodyType<PostPaymentBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPaymentHook>>>, TError,{data: BodyType<PostPaymentBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postPayment =  usePostPaymentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostPaymentHook>>>, {data: BodyType<PostPaymentBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postPayment(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostPaymentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostPaymentHook>>>>
    export type PostPaymentMutationBody = BodyType<PostPaymentBodies>
    export type PostPaymentMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 精算情報作成API
 */
export const usePostPayment = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPaymentHook>>>, TError,{data: BodyType<PostPaymentBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostPaymentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 精算の取り消し
- 取り消し理由を`Payments`テーブルに保存する
  - 本来はPATCHの動作だが、追加要件のためそのまま本APIで実装
# 画面URL
- https://x.gd/JMkJ2
 * @summary 精算情報取り消しAPI
 */
export const useDeletePaymentHook = () => {
        const deletePayment = useCustomClient<SuccessResponse>();

        return (
    deletePaymentBodies: BodyType<DeletePaymentBodies>,
 ) => {
        return deletePayment(
          {url: `/v1/payment`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deletePaymentBodies
    },
          );
        }
      }
    


export const useDeletePaymentMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePaymentHook>>>, TError,{data: BodyType<DeletePaymentBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePaymentHook>>>, TError,{data: BodyType<DeletePaymentBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deletePayment =  useDeletePaymentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeletePaymentHook>>>, {data: BodyType<DeletePaymentBodies>}> = (props) => {
          const {data} = props ?? {};

          return  deletePayment(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeletePaymentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletePaymentHook>>>>
    export type DeletePaymentMutationBody = BodyType<DeletePaymentBodies>
    export type DeletePaymentMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 精算情報取り消しAPI
 */
export const useDeletePayment = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePaymentHook>>>, TError,{data: BodyType<DeletePaymentBodies>}, TContext>, }
) => {
    
      const mutationOptions = useDeletePaymentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 精算項目を作成するAPI
 * @summary 精算項目を作成するAPI
 */
export const usePostPaymentItemsHook = () => {
        const postPaymentItems = useCustomClient<SuccessPostResponseIdResponse>();

        return (
    postPaymentItemsBodies: BodyType<PostPaymentItemsBodies>,
 ) => {
        return postPaymentItems(
          {url: `/v1/clinics/paymentItems`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postPaymentItemsBodies
    },
          );
        }
      }
    


export const usePostPaymentItemsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPaymentItemsHook>>>, TError,{data: BodyType<PostPaymentItemsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPaymentItemsHook>>>, TError,{data: BodyType<PostPaymentItemsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postPaymentItems =  usePostPaymentItemsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostPaymentItemsHook>>>, {data: BodyType<PostPaymentItemsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postPaymentItems(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostPaymentItemsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostPaymentItemsHook>>>>
    export type PostPaymentItemsMutationBody = BodyType<PostPaymentItemsBodies>
    export type PostPaymentItemsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 精算項目を作成するAPI
 */
export const usePostPaymentItems = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostPaymentItemsHook>>>, TError,{data: BodyType<PostPaymentItemsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostPaymentItemsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * 特定の医療機関の精算項目を取得するAPI
 * @summary 特定の医療機関の精算項目を取得するAPI
 */
export const useGetPaymentItemsByClinicIdHook = () => {
        const getPaymentItemsByClinicId = useCustomClient<GetPaymentItemsByClinicId200>();

        return (
    clinicId: string,
 signal?: AbortSignal
) => {
        return getPaymentItemsByClinicId(
          {url: `/v1/clinics/paymentItems/clinicId/${clinicId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPaymentItemsByClinicIdQueryKey = (clinicId: string,) => [`/v1/clinics/paymentItems/clinicId/${clinicId}`] as const;
  

    
export const useGetPaymentItemsByClinicIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPaymentItemsByClinicIdQueryKey(clinicId);

  const getPaymentItemsByClinicId =  useGetPaymentItemsByClinicIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>> = ({ signal }) => getPaymentItemsByClinicId(clinicId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions}}

export type GetPaymentItemsByClinicIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>>
export type GetPaymentItemsByClinicIdQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 特定の医療機関の精算項目を取得するAPI
 */
export const useGetPaymentItemsByClinicId = <TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentItemsByClinicIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPaymentItemsByClinicIdQueryOptions(clinicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 患者の入退時間とシステム日付を比較して計算する
- 診療IDはrequestBodyで送信する
  - 取得処理のためHTTPメソッドをGETにし、クエリパラメータで渡すことを検討
  - しかしクライアントから渡される診療IDの最大数とURLの長さの制限を考慮し、POSTを採用
 * @summary 患者入室時間分差取得API
 */
export const usePostConsultationsRoomHook = () => {
        const postConsultationsRoom = useCustomClient<PostConsultationsRoomResponseResponse>();

        return (
    postConsultationsRoomBodies: BodyType<PostConsultationsRoomBodies>,
 ) => {
        return postConsultationsRoom(
          {url: `/v1/consultations/room`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postConsultationsRoomBodies
    },
          );
        }
      }
    


export const usePostConsultationsRoomMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostConsultationsRoomHook>>>, TError,{data: BodyType<PostConsultationsRoomBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostConsultationsRoomHook>>>, TError,{data: BodyType<PostConsultationsRoomBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postConsultationsRoom =  usePostConsultationsRoomHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostConsultationsRoomHook>>>, {data: BodyType<PostConsultationsRoomBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postConsultationsRoom(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostConsultationsRoomMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostConsultationsRoomHook>>>>
    export type PostConsultationsRoomMutationBody = BodyType<PostConsultationsRoomBodies>
    export type PostConsultationsRoomMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者入室時間分差取得API
 */
export const usePostConsultationsRoom = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostConsultationsRoomHook>>>, TError,{data: BodyType<PostConsultationsRoomBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostConsultationsRoomMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    